import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import Template from "../components/Template";
import logo from "../assets/logo.png";
import moment from "moment";

const Receipt = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const transaction = location.state?.transaction;
  const user = location.state?.user;

  useEffect(() => {
    if(!transaction) return navigate("/dashboard")
  }, [])

  const handleCancel = () => {
    navigate("/transactionhistory");
  };

  const handlePrint = () => {
    // Hide non-printable elements
    document.querySelectorAll(".non-printable").forEach((element) => {
      element.style.display = "none";
    });

    // Trigger printing
    window.print();

    // Restore visibility of non-printable elements
    document.querySelectorAll(".non-printable").forEach((element) => {
      element.style.display = "";
    });
  };

  const capitalizeFirstLetter = (string) => {
    if (string) {
      return `${string.charAt(0).toUpperCase()}${string.slice(1)}`;
    }
  };

  return (
    <>
      <div className="non-printable">
        <Template />
      </div>

      <div className="p-4 sm:ml-64 content-section ">
        <div className="p-4 produce-form-wrapper">
          <div className="produce-form pt-2">
            <div className="flex justify-end">
              <i
                className="bi bi-x-lg"
                style={{ fontSize: "30px", cursor: "pointer" }}
                onClick={handleCancel}
              ></i>
            </div>
            <div className="flex justify-center">
              <img
                src={logo}
                alt="done"
                style={{ width: "100px", height: "auto" }}
              />
            </div>

            <div className="text-center mt-2 mb-8">
              <h4 style={{ color: "#535353" }}>Transaction Receipt</h4>
              <span style={{ color: "gray" }}>
                Generated by First Monument on{" "}
                {moment().format("LLL")}
              </span>
            </div>

            <table className="items-center w-full border-collapse">
              <tbody>
                <tr id="receipt-row">
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blueGray-700 ">
                    Transaction Amount:
                  </td>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 receipt-data">
                    ${transaction?.amount.toLocaleString()}
                  </td>
                </tr>
                <tr id="receipt-row">
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blueGray-700 ">
                    Transaction Type:
                  </td>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 receipt-data">
                    {transaction?.type}
                  </td>
                </tr>
                <tr id="receipt-row">
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blueGray-700 ">
                    Transaction Date:
                  </td>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 receipt-data">
                    {moment(transaction?.date).format("LL")}
                  </td>
                </tr>
                <tr id="receipt-row">
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blueGray-700 ">
                    Sender:
                  </td>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 receipt-data">
                    {`${capitalizeFirstLetter(
                      user?.firstName
                    )}  ${capitalizeFirstLetter(user?.secondName)}`}
                  </td>
                </tr>
                <tr id="receipt-row">
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blueGray-700 ">
                    Beneficiary:
                  </td>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 receipt-data">
                    {capitalizeFirstLetter(transaction?.accountHolder)}
                    <br /> <br />
                    {transaction?.accountNumber}
                  </td>
                </tr>
                <tr id="receipt-row">
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blueGray-700 ">
                    Transaction Reference:
                  </td>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 receipt-data">
                    {transaction?.referenceId}
                  </td>
                </tr>
              </tbody>
            </table>

            <button
              onClick={handlePrint}
              style={{ backgroundColor: "#216206" }}
              type="button"
              className="text-white w-full focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-4"
            >
              Print Receipt
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Receipt;
