import React from "react";
import useTransactionStore from "../stores/transactionStore";
import { useNavigate } from "react-router-dom";
import useAuthStore from "../stores/authStore";
import moment from "moment";

const HistorySection = () => {
  const navigate = useNavigate();
  const user = useAuthStore((state) => state.user);
  const transactions = useTransactionStore((state) => state.transactions);

  const handleReceipt = (transaction) => {
    navigate("/receipt", { state: { transaction, user } });
  };




  return (
    <>
      <div class="p-4 sm:ml-64 dashboard-section">
        {/* ============== Transaction Section ======================== */}
        <h3 className=" mb-2 mt-8 dashboard-label">Transaction History</h3>

        <div class="block w-full overflow-x-auto">
          <table class="items-center  w-full border-collapse ">
            <thead>
              <tr>
                <th class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Amount
                </th>
                <th class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Date
                </th>
                <th class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Transaction ID
                </th>
                <th class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Type
                </th>
                <th class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Receipt
                </th>
              </tr>
            </thead>

            <tbody>
              {transactions?.map((transaction) => (
                <React.Fragment>
                  <tr>
                    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blueGray-700 ">
                      ${transaction.amount.toLocaleString()}
                    </td>
                    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 ">
                      {moment(transaction.date).format("LL")}
                    </td>
                    <td class="border-t-0 px-6 align-center border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {transaction.referenceId}
                    </td>
                    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      <span
                        className="badge"
                        id={`${
                          transaction.type === "debit" ? "danger" : "approved"
                        }`}
                      >
                        {transaction.type}
                      </span>
                    </td>
                    <td class="border-t-0 px-6 align-center border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      <button
                        className="receipt-btn"
                        onClick={() => handleReceipt(transaction)}
                      >
                        Receipt
                      </button>
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default HistorySection;
