import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

const useLoans = create(
  devtools(
    persist(
      (set) => ({
        loans: [],

        storeLoan: (loan) =>
          set((state) => ({
            loans: loan,
          })),

        clearLoans: () =>
          set(() => ({
            loans: [],
          })),
      }),
      { name: "loans" }
    )
  )
);

export default useLoans;
