import coin from "../assets/coin.png";
import clip from "../assets/clip.png";
import useAuthStore from "../stores/authStore";
import { useEffect, useState } from "react";
import MessageAlert from "./MessageAlert";
import Loader from "./Loader";

const DepositSection = () => {
  const user = useAuthStore((state) => state.user);
  const crypto = useAuthStore((state) => state.cryptoTypes);

  const [isLoading, setIsLoading] = useState(false); // Corrected `islaoding`
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [messageID, setMessageID] = useState("");

  const [profileImg, setProfileImg] = useState("");
  const [imgUpdated, setImgUpdated] = useState(false);
  const [imgSize, setImgSize] = useState(0);

  const [formData, setFormData] = useState({
    amount: null,
    email: user?.email,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  function handleCopy(e) {
    navigator.clipboard.writeText(e);
  }

  // --------------- Hand Submit ----------------
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await fetch(`https://api-bank.bitinverse.com/api/v1/deposit`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.msg || "Something went wrong!");
      }

      setMessage(result.msg);
      setMessageID("successAlert");
      setShowMessage(true);
      setIsLoading(false);

      setFormData({
        amount: "",
        email: user?.email,
      });
    } catch (error) {
      setMessage(error.message || "An unexpected error occurred");
      setMessageID("declineAlert");
      setShowMessage(true);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  // -------------- Handle image upload ----------------------
  const handleAddProfile = (e) => {
    const file = e.target.files[0];

    if (file?.size > 2 * 1024 * 1024) {
      setMessage("File size should not exceed 2MB");
      setMessageID("declineAlert");
      setShowMessage(true);
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = () => {
      const image = new Image();
      image.src = reader.result;

      image.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        // Set the desired dimensions (resize/compress)
        const maxWidth = 600;
        const maxHeight = 600;
        let width = image.width;
        let height = image.height;

        // Maintain aspect ratio
        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(image, 0, 0, width, height);

        const compressedBase64 = canvas.toDataURL("image/jpeg", 0.9);
        setProfileImg(compressedBase64);
        setFormData((prevFormData) => ({
          ...prevFormData,
          proof: compressedBase64,
        }));

        setImgUpdated(true); // Set to true after the image is fully processed
      };
    };
  };

  // Hide message after 5 seconds
  useEffect(() => {
    if (showMessage) {
      const timer = setTimeout(() => {
        setShowMessage(false);
      }, 5000); // Hide message after 5 seconds

      return () => clearTimeout(timer); // Cleanup the timer
    }
  }, [showMessage]);
  return (
    <>
      {showMessage ? <MessageAlert data={{ message, messageID }} /> : ""}

      <div class="p-4 sm:ml-64 content-section">
        <div className="p-4 produce-form-wrapper">
          <div className="produce-form">
            <form onSubmit={handleSubmit}>
              <div className="text-center">
                <h3 className="mb-8 dashboard-label">Crypto Deposit</h3>
              </div>
              <div className="mb-5 text-center">
                <p style={{ color: "gray" }}>
                  Make your deposit to either of the two wallet addresses, then
                  insert the amount you've deposited and proof of payment below.
                </p>
              </div>

              <div className="mb-4">
                <label
                  for="type"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  BTC (Bitcoin) Address:
                </label>

                <div className="cryptoWrapper">
                  <input
                    type="readOnly"
                    value={crypto?.btc}
                    className="cryptoAddress text-sm p-2.5 w-full"
                  />

                  <button type="button" className="copyBtn">
                    <img src={clip} alt="copy" />
                  </button>
                </div>
              </div>

              <div className="mb-4">
                <label
                  for="type"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Ethereum (ETH) Address:
                </label>

                <div className="cryptoWrapper">
                  <input
                    type="readOnly"
                    value={crypto?.eth}
                    className="cryptoAddress text-sm p-2.5 w-full"
                  />

                  <button type="button" className="copyBtn">
                    <img src={clip} alt="copy" />
                  </button>
                </div>
              </div>

              <div className="mb-4">
                <label
                  for="type"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  USDT (TRC20) Address:
                </label>

                <div className="cryptoWrapper">
                  <input
                    type="readOnly"
                    value={crypto?.usdt}
                    className="cryptoAddress text-sm p-2.5 w-full"
                  />

                  <button type="button" className="copyBtn">
                    <img src={clip} alt="copy" />
                  </button>
                </div>
              </div>

              <div class="mb-4">
                <label
                  for="type"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Amount
                </label>
                <input
                  type="number"
                  id="type"
                  class="shadow-sm produce-input block w-full p-2.5 "
                  name="amount"
                  value={formData.amount}
                  onChange={handleChange}
                  required
                />
              </div>

              <div class="mb-4">
                <label
                  for="price"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Proof of payment
                </label>
                <input
                  type="file"
                  id="price"
                  class="shadow-sm produce-input block w-full p-2.5 "
                  name="price"
                  onChange={handleAddProfile}
                  accept=".jpg, .jpeg, .png"
                  required
                />
              </div>

              <button
                style={{ backgroundColor: "#216206" }}
                type="submit"
                class="text-white w-full  focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              >
                {isLoading ? <Loader /> : "Proceed"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default DepositSection;
