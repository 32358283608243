import { useEffect, useState } from "react";
import moment from "moment";
import useAuthStore from "../stores/authStore";
import { useNavigate } from "react-router";
import useTransactionStore from "../stores/transactionStore";
import Loader from "../components/Loader";
import MessageAlert from "./MessageAlert";

const LocalSection = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [charge, setCharge] = useState(0);
  const user = useAuthStore((state) => state.user);
  const showBalance = useAuthStore((state) => state.showBalance);
  const handleShowBalance = useAuthStore((state) => state.handleShowBalance);
  const login = useAuthStore((state) => state.login);
  const transfer = useTransactionStore((state) => state.transfer);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [messageID, setMessageID] = useState("");
  const [formData, setFormData] = useState({
    amount: null,
    bankName: "",
    accountNumber: "",
    accountHolder: "",
    details: "",
    type: "debit",
    email: user?.email,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleHideBalance = () => {
    handleShowBalance(!showBalance);
  };

  useEffect(() => {
    const handleCharge = () => {
      const charge = (formData.amount * 4) / 1000;
      setCharge(charge);
    };
    handleCharge();
  }, [formData.amount]);

  const handleTransfer = async (e) => {
    e.preventDefault();
    const {
      amount,
      bankName,
      accountNumber,
      accountHolder,
      details,
      identifier,
      code,
      country,
      type,
      email: any,
    } = formData;

    if (user.status === "suspended") return navigate("/declinetransfer");

    setIsLoading(true);

    try {
      if (
        formData.amount > Number(user?.totalBalance) ||
        formData.amount === 0
      ) {
        throw new Error("Insufficient balance, please fund your account");
      }

      const result = await (
        await fetch(`https://api-bank.bitinverse.com/api/v1/transfer`, {
          method: "POST",

          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({
            amount: parseInt(amount) + parseFloat(charge),
            bankName,
            accountNumber,
            accountHolder,
            details,
            identifier,
            code,
            country,
            type,
            email: any,
          }),
        })
      ).json();

      transfer(result.allTransactions);
      login(result.user);
      setIsLoading(false);

      navigate("/successfultransfer", { state: { result, charge } });
    } catch (error) {
      console.log(error);
      setMessage(error.message || "An unexpected error occurred");
      setMessageID("declineAlert");
      setShowMessage(true);
      setIsLoading(false);
    }
  };

  // Hide message after 5 seconds
  useEffect(() => {
    if (showMessage) {
      const timer = setTimeout(() => {
        setShowMessage(false);
      }, 5000); // Hide message after 5 seconds

      return () => clearTimeout(timer); // Cleanup the timer
    }
  }, [showMessage]);

  return (
    <>
      {showMessage ? <MessageAlert data={{ message, messageID }} /> : ""}

      <div class="p-4 sm:ml-64 content-section">
        <div className="p-4 produce-form-wrapper">
          <div className="produce-form">
            <form class="" onSubmit={handleTransfer}>
              <h3 className="mb-8 dashboard-label">Local Transfer</h3>

              {/* ------------- Banner --------------------- */}
              <div className="transfer-balance">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h5>Your Balance</h5>

                  {/* {showBalance ? (
                    <li
                      className="bi bi-eye-slash eye-drop"
                      onClick={handleHideBalance}
                    ></li>
                  ) : (
                    <li
                      className="bi bi-eye eye-drop"
                      onClick={handleHideBalance}
                    ></li>
                  )} */}
                </div>

                <span id="balance" style={{ fontSize: "30px" }}>
                  {" "}
                  {/* {showBalance
                    ? "$****"
                    : `$${user?.totalBalance.toLocaleString()}`} */}
                  {`$${user?.totalBalance.toLocaleString()}`}
                </span>

                <br />

                <span style={{ float: "right", fontSize: "12px" }}>
                  {`Last updated ${moment().calendar()}`}
                </span>
              </div>

              <div class="mb-4">
                <label
                  for="type"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Amount
                </label>
                <input
                  type="number"
                  id="type"
                  class="shadow-sm produce-input block w-full p-2.5 "
                  name="amount"
                  value={formData.amount}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="chargesContainer">
                <div className="chargeWrapper">
                  <span>Charges &nbsp; </span>{" "}
                  <li class="bi bi-caret-right"></li> &nbsp;
                  <span style={{ color: "rgb(245, 67, 67)" }}>
                    {charge ? `$${charge}` : "$0.00"}
                  </span>
                </div>
              </div>

              <div class="mb-4">
                <label
                  for="price"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Bank Name
                </label>
                <input
                  type="text"
                  id="type"
                  class="shadow-sm produce-input block w-full p-2.5 "
                  name="bankName"
                  value={formData.bankName}
                  onChange={handleChange}
                  required
                />
              </div>

              <div class="mb-4">
                <label
                  for="availability"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Account Number
                </label>
                <input
                  type="number"
                  id="type"
                  class="shadow-sm produce-input block w-full p-2.5 "
                  name="accountNumber"
                  value={formData.accountNumber}
                  onChange={handleChange}
                  required
                />
              </div>

              <div class="mb-4">
                <label
                  for="availability"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Account Holder
                </label>
                <input
                  type="text"
                  id="type"
                  class="shadow-sm produce-input block w-full p-2.5 "
                  name="accountHolder"
                  value={formData.accountHolder}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="mb-4">
                <label
                  for="availability"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Details (Optional)
                </label>
                <textarea
                  class="peer h-full shadow-sm produce-input block w-full p-2.5  "
                  name="details"
                  value={formData.details}
                  onChange={handleChange}
                ></textarea>
              </div>

              <button
                style={{ backgroundColor: "#216206" }}
                type="submit"
                class="text-white w-full  focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              >
                {isLoading ? <Loader /> : "Transfer"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default LocalSection;
