import { useLocation, useNavigate } from "react-router";
import AdminTemplate from "../components/AdminTemplate";
import { useEffect, useRef, useState } from "react";
import Loader from "../components/Loader";
import DarkLoader from "../components/DarkLoader";
import MessageAlert from "../components/MessageAlert";
import { Button, Modal } from "flowbite-react";
import moment from "moment";
import useAuthStore from "../stores/authStore";

const ManageUser = () => {
  const localUser = useAuthStore((state) => state.user);
  const navigate = useNavigate();
  const location = useLocation();
  const [openModal, setOpenModal] = useState(false);

  const [isTransfering, setIsTransfering] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);
  const [isRoling, setIsRoling] = useState(false);

  const [makingAdmin, setMakingAdmin] = useState(false);
  const [makingUser, setMakingUser] = useState(false);

  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [messageID, setMessageID] = useState("");

  const [combinedImage, setCombinedImage] = useState(null);

  // Initialize user state from localStorage or location.state
  const [user, setUser] = useState(() => {
    const storedUser = JSON.parse(localStorage.getItem("managedData"));
    return storedUser !== null ? storedUser : location.state?.aUser;
  });

  useEffect(() => {
    const topHalf = user?.kycTopHalf; // Base64 image string for top half
    const bottomHalf = user?.kycBottomHalf; // Base64 image string for bottom half

    if (topHalf && bottomHalf) {
      // Create a canvas to combine the two halves
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      const imgTop = new Image();
      const imgBottom = new Image();

      imgTop.src = topHalf;
      imgBottom.src = bottomHalf;

      imgTop.onload = () => {
        canvas.width = imgTop.width;
        canvas.height = imgTop.height + imgBottom.height; // Set height to accommodate both halves
        ctx.drawImage(imgTop, 0, 0);
        ctx.drawImage(imgBottom, 0, imgTop.height); // Draw bottom half below the top half

        // Convert the canvas to a base64 image
        const combinedBase64 = canvas.toDataURL("image/jpeg");
        setCombinedImage(combinedBase64);
      };
    }
  }, []);

  useEffect(() => {
    if (!user) {
      navigate("/allusers");
    } else {
      const storedUser = JSON.parse(localStorage.getItem("managedData"));
      setUser(storedUser !== null ? storedUser : location.state?.aUser);
    }
  }, [navigate, location.state?.aUser]);

  const [formData, setFormData] = useState({
    amount: null,
    accountNumber: "",
    accountHolder: "",
    type: "",
    email: user?.email,
    details: "",
  });

  const [updateData, setUpdateData] = useState({
    email: user?.email,
    totalBalance: null,
    status: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Transfer function
  const handleTransfer = async (e) => {
    e.preventDefault();
    setIsTransfering(true);

    try {
      const result = await fetch(`https://api-bank.bitinverse.com/api/v1/transfer`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      }).then((res) => res.json());

      localStorage.setItem("managedData", JSON.stringify(result.user));
      setUser(result.user);

      setMessage("Transaction Successful!");
      setMessageID("successAlert");
      setShowMessage(true);
      setIsTransfering(false);
      setFormData({
        amount: "",
        accountNumber: "",
        accountHolder: "",
        type: "",
        email: user?.email,
        details: "",
      });
    } catch (error) {
      console.error(error);
    }
  };

  // ---- Approve User
  const approveUser = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      if (user?.status === "approved") {
        throw new Error("User already approved");
      } else if (user?.status == "suspended") {
        throw new Error("Please use the unsuspend button");
      }

      const response = await fetch(`https://api-bank.bitinverse.com/api/v1/approveuser`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({ email: user?.email, status: "approved" }),
      });

      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.msg || "Something went wrong!");
      }

      localStorage.setItem("managedData", JSON.stringify(result));
      setUser(result);

      setMessage("Profile updated successfully");
      setMessageID("successAlert");
      setShowMessage(true);
      setIsLoading(false);
    } catch (error) {
      setMessage(error.message || "An unexpected error occurred");
      setMessageID("declineAlert");
      setShowMessage(true);
      setIsLoading(false);
    }
  };
  // ---- Unsuspend User
  const unsuspendUser = async (e) => {
    e.preventDefault();
    setIsFetching(true);

    try {
      if (user?.status === "approved") {
        throw new Error("User already approved");
      } else if (user?.status == "pending") {
        throw new Error("Please use the approve button");
      }

      const response = await fetch(
        `https://api-bank.bitinverse.com/api/v1/unsuspenduser`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({ email: user?.email, status: "approved" }),
        }
      );

      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.msg || "Something went wrong!");
      }

      localStorage.setItem("managedData", JSON.stringify(result));
      setUser(result);

      setMessage("Profile updated successfully");
      setMessageID("successAlert");
      setShowMessage(true);
      setIsFetching(false);
    } catch (error) {
      setMessage(error.message || "An unexpected error occurred");
      setMessageID("declineAlert");
      setShowMessage(true);
      setIsFetching(false);
    }
  };

  // ---- Suspend User
  const suspendUser = async (e) => {
    e.preventDefault();
    setIsSending(true);

    try {
      if (user?.status === "suspended") {
        throw new Error("User already suspended");
      } else if (user?.status == "pending") {
        throw new Error("User can only be suspended after approval");
      }

      const response = await fetch(`https://api-bank.bitinverse.com/api/v1/suspenduser`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({ email: user?.email, status: "suspended" }),
      });

      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.msg || "Something went wrong!");
      }

      localStorage.setItem("managedData", JSON.stringify(result));
      setUser(result);

      setMessage("Profile updated successfully");
      setMessageID("successAlert");
      setShowMessage(true);
      setIsSending(false);
    } catch (error) {
      setMessage(error.message || "An unexpected error occurred");
      setMessageID("declineAlert");
      setShowMessage(true);
      setIsSending(false);
    }
  };

  // Update Profile function
  const handleUpdateProfile = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const result = await fetch(`https://api-bank.bitinverse.com/api/v1/updateprofile`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updateData),
      }).then((res) => res.json());

      localStorage.setItem("managedData", JSON.stringify(result));
      setUser(result);

      setMessage("Profile updated successfully");
      setMessageID("successAlert");
      setShowMessage(true);
      setIsLoading(false);
      setUpdateData({
        email: user?.email,
        totalBalance: "",
        status: "",
      });
    } catch (error) {
      console.error(error);
    }
  };

  // ---- Approve KYC
  const approveKYC = async (e) => {
    e.preventDefault();
    setIsApproving(true);

    try {
      if (user?.kycStatus === "approved") {
        throw new Error("KYC already approved");
      } else if (user?.status == "pending") {
        throw new Error("User can only be suspended after approval");
      }

      const response = await fetch(`https://api-bank.bitinverse.com/api/v1/approvekyc`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({ email: user?.email, status: "approved" }),
      });

      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.msg || "Something went wrong!");
      }

      localStorage.setItem("managedData", JSON.stringify(result));
      setUser(result);

      setMessage("Profile updated successfully");
      setMessageID("successAlert");
      setShowMessage(true);
      setIsApproving(false);
    } catch (error) {
      setMessage(error.message || "An unexpected error occurred");
      setMessageID("declineAlert");
      setShowMessage(true);
      setIsApproving(false);
    }
  };

  // ---- Approve KYC
  const rejectKYC = async (e) => {
    e.preventDefault();
    setIsRejecting(true);

    try {
      if (user?.kycStatus === "rejected") {
        throw new Error("KYC already rejected");
      }

      const response = await fetch(`https://api-bank.bitinverse.com/api/v1/rejectkyc`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({ email: user?.email, status: "rejected" }),
      });

      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.msg || "Something went wrong!");
      }

      localStorage.setItem("managedData", JSON.stringify(result));
      setUser(result);

      setMessage("Profile updated successfully");
      setMessageID("successAlert");
      setShowMessage(true);
      setIsRejecting(false);
    } catch (error) {
      setMessage(error.message || "An unexpected error occurred");
      setMessageID("declineAlert");
      setShowMessage(true);
      setIsRejecting(false);
    }
  };

  // ------ Update Role
  const updateRole = async (e) => {
    const value = e.target.value;

    if (value === "super") {
      setIsRoling(true);
    } else if (value === "admin") {
      setMakingAdmin(true);
    } else {
      setMakingUser(true);
    }

    try {
      if (localUser?.role === "admin" && value === "super") {
        throw new Error("Sorry, admin cannot make super admin");
      }

      const response = await fetch(`https://api-bank.bitinverse.com/api/v1/updaterole`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({ email: user?.email, role: value }),
      });

      const result = await response.json();
      if (!response.ok) {
        throw new Error(result.msg || "Something went wrong!");
      }

      localStorage.setItem("managedData", JSON.stringify(result));
      setUser(result);

      setIsRoling(false);
      setMakingAdmin(false);
      setMakingUser(false);
    } catch (error) {
      setMessage(error.message || "An unexpected error occurred");
      setMessageID("declineAlert");
      setShowMessage(true);
      setIsRoling(false);
      setMakingAdmin(false);
      setMakingUser(false);
    }
  };

  function onCloseModal() {
    setOpenModal(false);
  }

  const displayKYC = () => {
    setOpenModal(true);
  };

  const handleDownload = () => {
    if (!user?.kyc) return;

    const link = document.createElement("a");
    link.href = user?.kyc;
    link.download = "kyc.jpg"; // Name of the downloaded file
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <AdminTemplate />

      {showMessage ? (
        <MessageAlert
          data={{ message, messageID }}
          onDurationChange={setTimeout(() => {
            setShowMessage(false);
          }, 2000)}
        />
      ) : (
        ""
      )}

      <Modal show={openModal} size="md" onClose={onCloseModal} popup>
        <Modal.Header />
        <Modal.Body>
          <div className="space-y-6">
            <div>
              {combinedImage ? (
                <img src={combinedImage} alt="Combined KYC" />
              ) : (
                <p>Loading...</p>
              )}
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button color="dark" onClick={handleDownload}>
            Download
          </Button>
        </Modal.Footer>
      </Modal>

      <div class="p-4 sm:ml-64 dashboard-section ">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div class="bg-gray-200 p-4">
            {/* <img src={user?.kyc} alt=""style={{ width: '100%', height: 'auto', objectFit: 'contain' }} /> */}

            <h3 className="mb-8 mt-3 dashboard-label">User Data</h3>
            <div className="pictureHolder">
              <div
                className="profileBox"
                style={{
                  backgroundImage: `url(${user?.profilePicture})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                {" "}
              </div>
            </div>
            <hr />
            <table class="items-center  w-full border-collapse ">
              <tbody>
                <tr>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-3 text-left text-blueGray-700 ">
                    Name:
                  </td>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-3 ">
                    {`${user?.firstName} ${user?.secondName}`}
                  </td>
                </tr>

                <tr>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-3 text-left text-blueGray-700 ">
                    Date of Birth:
                  </td>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-3 ">
                    {user?.dob}
                  </td>
                </tr>

                <tr>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-3 text-left text-blueGray-700 ">
                    Account Number:
                  </td>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-3 ">
                    {user?.accountNumber}
                  </td>
                </tr>

                <tr>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-3 text-left text-blueGray-700 ">
                    Account Type:
                  </td>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-3 ">
                    {user?.accountType}
                  </td>
                </tr>

                <tr>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-3 text-left text-blueGray-700 ">
                    Password:
                  </td>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-3 ">
                    {user?.password}
                  </td>
                </tr>

                <tr>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-3 text-left text-blueGray-700 ">
                    Phone:
                  </td>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-3 ">
                    {user?.phone}
                  </td>
                </tr>

                <tr>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-3 text-left text-blueGray-700 ">
                    Email:
                  </td>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-3 ">
                    {user?.email}
                  </td>
                </tr>

                <tr>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-3 text-left text-blueGray-700 ">
                    Country:
                  </td>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-3 ">
                    {user?.country}
                  </td>
                </tr>

                <tr>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-3 text-left text-blueGray-700 ">
                    State:
                  </td>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-3 ">
                    {user?.state}
                  </td>
                </tr>

                <tr>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-3 text-left text-blueGray-700 ">
                    city:
                  </td>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-3 ">
                    {user?.city}
                  </td>
                </tr>

                <tr>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-3 text-left text-blueGray-700 ">
                    ZipCode:
                  </td>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-3 ">
                    {user?.zipCode}
                  </td>
                </tr>

                <tr>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-3 text-left text-blueGray-700 ">
                    Created On:
                  </td>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-3 ">
                    {moment(user?.createdAt).format("ll")}
                  </td>
                </tr>

                <tr>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-3 text-left text-blueGray-700 ">
                    User Status:
                  </td>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-3 ">
                    <span
                      className="badge"
                      id={`${
                        user?.status === "suspended"
                          ? "danger"
                          : user?.status === "pending"
                          ? "suspended"
                          : "approved"
                      }`}
                    >
                      {user?.status}
                    </span>
                  </td>
                </tr>

                <tr>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-3 text-left text-blueGray-700 ">
                    KYC Status:
                  </td>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-3 ">
                    <span
                      className="badge"
                      id={`${
                        user?.kycStatus === "rejected"
                          ? "danger"
                          : user?.kycStatus === "pending"
                          ? "suspended"
                          : "approved"
                      }`}
                    >
                      {user?.kycStatus}
                    </span>
                  </td>
                </tr>

                <tr>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-3 text-left text-blueGray-700 ">
                    USD Balance:
                  </td>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-3 ">
                    ${user?.totalBalance}
                  </td>
                </tr>
              </tbody>
            </table>

            {/* <form onSubmit={handleUpdateProfile}>
              <div class="mb-4 mt-4">
                <label
                  for="type"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Total Balance
                </label>
                <input
                  type="number"
                  id="type"
                  class="shadow-sm produce-input block w-full p-2.5 "
                  name="totalBalance"
                  value={updateData.totalBalance}
                  onChange={handleGrabData}
                />
              </div>

              <div class="mb-4">
                <label
                  for="type"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Status
                </label>
                <select
                  className=" w-full peer h-full shadow-sm produce-input block p-2.5"
                  name={"status"}
                  value={updateData.status}
                  onChange={handleGrabData}
                >
                  <option value="">---select---</option>
                  <option value="active">Active</option>
                  <option value="blocked">Blocked</option>
                </select>
              </div>

              <button
                style={{ backgroundColor: "#216206" }}
                type="submit"
                class="text-white w-full mt-8 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              >
                {isLoading ? <Loader /> : " Update Profile"}
              </button>
            </form> */}
            <h3 className="mb-8 mt-3 dashboard-label">Update User Status</h3>
            <div className="updateWrapper">
              <Button
                disabled={isLoading || isSending || isFetching}
                color="success"
                onClick={approveUser}
              >
                {isLoading ? <Loader /> : "Approve"}
              </Button>

              <Button
                disabled={isLoading || isSending || isFetching}
                onClick={suspendUser}
                color="warning"
              >
                {isSending ? <Loader /> : "Suspend"}
              </Button>

              <Button
                disabled={isLoading || isSending || isFetching}
                onClick={unsuspendUser}
                color="purple"
              >
                {isFetching ? <Loader /> : "Unsuspend"}
              </Button>
            </div>
            <hr />

            <h3 className="mb-8 mt-3 dashboard-label">
              Know Your Customer(KYC)
            </h3>

            <div className="updateWrapper">
              <Button onClick={displayKYC} color="dark">
                View KYC
              </Button>

              <Button.Group>
                <Button
                  disabled={isApproving || isRejecting}
                  color="gray"
                  onClick={approveKYC}
                >
                  {isApproving ? <DarkLoader /> : "Approve"}
                </Button>
                <Button
                  onClick={rejectKYC}
                  disabled={isApproving || isRejecting}
                  color="gray"
                >
                  {isRejecting ? <DarkLoader /> : "Reject"}
                </Button>
              </Button.Group>
            </div>

            <hr />

            <div className="statusWrapper">
              <h3 className="mb-8 mt-3 dashboard-label">User Role</h3>
              <div className="status" style={{ fontWeight: "bolder" }}>
                {user?.role}
              </div>
            </div>

            <div className="updateWrapper">
              <button
                className="roleButton"
                value="super"
                onClick={(e) => updateRole(e)}
                disabled={isRoling || makingAdmin || makingUser}
              >
                {isRoling ? <DarkLoader /> : "Make Super Admin"}
              </button>

              <button
                className="roleButton"
                value="admin"
                onClick={(e) => updateRole(e)}
                disabled={isRoling || makingAdmin || makingUser}
              >
                {makingAdmin ? <DarkLoader /> : "Make Admin"}
              </button>

              <button
                className="roleButton"
                value="user"
                onClick={(e) => updateRole(e)}
                disabled={isRoling || makingAdmin || makingUser}
              >
                {makingUser ? <DarkLoader /> : "Make user"}
              </button>
            </div>
          </div>

          {/* -------------- col 2 ----------------------------- */}
          <div class="bg-gray-200 p-4">
            <form onSubmit={handleTransfer}>
              <h3 className="mb-8 dashboard-label">Balance Update</h3>

              <div class="mb-4">
                <label
                  for="type"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Amount ($)
                </label>
                <input
                  type="number"
                  id="type"
                  class="shadow-sm produce-input block w-full p-2.5 "
                  name="amount"
                  value={formData.amount}
                  onChange={handleChange}
                  required
                />
              </div>

              <div class="mb-4">
                <label
                  for="type"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Account Number
                </label>
                <input
                  type="text"
                  id="type"
                  class="shadow-sm produce-input block w-full p-2.5 "
                  name="accountNumber"
                  value={formData.accountNumber}
                  onChange={handleChange}
                  required
                />
              </div>

              <div class="mb-4">
                <label
                  for="type"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Account Holder
                </label>
                <input
                  type="text"
                  id="type"
                  class="shadow-sm produce-input block w-full p-2.5 "
                  name="accountHolder"
                  value={formData.accountHolder}
                  onChange={handleChange}
                  required
                />
              </div>

              <div class="flex items-center mb-4">
                <input
                  id="credit"
                  type="radio"
                  name="type"
                  checked={formData.type === "credit"}
                  value="credit"
                  onChange={handleChange}
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  for="credit"
                  class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Credit
                </label>
              </div>

              <div class="flex items-center">
                <input
                  id="debit"
                  type="radio"
                  checked={formData.type === "debit"}
                  value="debit"
                  name="type"
                  onChange={handleChange}
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  for="debit"
                  class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Debit
                </label>
              </div>

              <div className="mb-4 mt-4">
                <label
                  for="availability"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Details
                </label>
                <textarea
                  class="peer h-full shadow-sm produce-input block w-full p-2.5  "
                  name="details"
                  value={formData.details}
                  onChange={handleChange}
                ></textarea>
              </div>

              <button
                style={{ backgroundColor: "#216206" }}
                type="submit"
                class="text-white w-full  focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              >
                {isTransfering ? <Loader /> : "Update Balance"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageUser;
