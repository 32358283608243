import { Button, Card } from "flowbite-react";
import AdminTemplate from "../components/AdminTemplate";
import woman from "../assets/woman.png";
import useDepositSore from "../stores/depositStore";
import { useLocation, useNavigate } from "react-router";
import { useEffect, useState } from "react";
import moment from "moment";

const UserDeposits = () => {
  const updateDeposits = useDepositSore((state) => state.storeDeposit);
  const deposits = useDepositSore((state) => state.deposits);
  const location = useLocation();
  const navigate = useNavigate();
  const user = location.state?.user;
  const userEmail = user?.email;
  //   const [proof, setProof] = useState("")

  // Get Deposits
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await (
          await fetch(`https://api-bank.bitinverse.com/api/v1/getalldeposit`, {
            method: "POST",

            headers: {
              "content-type": "application/json",
            },
            body: JSON.stringify({ email: userEmail }),
          })
        ).json();

        updateDeposits(result);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, []);

  const handleDownload = (proof) => {
    if (!proof) return;

    const link = document.createElement("a");
    link.href = proof;
    link.download = "depositproof.jpg"; // Name of the downloaded file
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <AdminTemplate />

      <div class="p-4 sm:ml-64 dashboard-section">
        <h3 className=" mb-2  dashboard-label"> Deposits</h3>

        <div id="depositwrapper">
          {deposits?.map(({ proof, amount, createdAt }) => (
            <Card className="max-w-sm mb-6" imgSrc={proof} vertical>
              <p className="font-normal text-gray-700 dark:text-gray-400">
                Amount: ${amount}
              </p>
              <p className="font-normal text-gray-700 dark:text-gray-400">
                Date: {moment(createdAt).format("LLL")}
              </p>

              <Button color="dark" onClick={() => handleDownload(proof)}>
                Download
              </Button>
            </Card>
          ))}
        </div>
      </div>
    </>
  );
};

export default UserDeposits;
