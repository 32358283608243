import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import useTransactionStore from "./transactionStore";
import useLoans from "./loanStore";
import useDepositSore from "./depositStore";

const useAuthStore = create(
  devtools(
    persist(
      (set, get) => ({
        isLoggedIn: false,
        user: null,
        cryptoTypes: null,
        showBalance: true,

        login: (userData) =>
          set({
            isLoggedIn: true,
            user: userData, // Replace with the new user data
          }),
        
          

        setCrypto: (types) =>
          set((state) => ({
            cryptoTypes: { ...state.cryptoTypes, ...types },
          })),

        handleShowBalance: (show) => {
          set((state) => ({
            showBalance: show,
          }));
        },

        logout: () => {
          // Clear user state
          set(() => ({
            isLoggedIn: false,
            user: null,
            cryptoTypes: null,
          }));

          // Clear transactions array in useTransactionStore
          useTransactionStore.getState().clearTransactions();
          useLoans.getState().clearLoans();
          useDepositSore.getState().clearDeposits();
        },
      }),
      { name: "profile" }
    )
  )
);

export default useAuthStore;
