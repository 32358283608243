import useAuthStore from "../stores/authStore";
import React, { useEffect, useState } from "react";
import MessageAlert from "./MessageAlert";
import Loader from "./Loader";
import successPng from "../assets/success.png";
import { useNavigate } from "react-router";

const KYCSection = () => {
  const navigate = useNavigate();
  const user = useAuthStore((state) => state.user);
  const updateUser = useAuthStore((state) => state.login);

  const [isLoading, setIsLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [messageID, setMessageID] = useState("");
  const [fileName, setFileName] = useState("");

  const [profileImg, setProfileImg] = useState("");
  const [imgUpdated, setImgUpdated] = useState(false);

  const [formData, setFormData] = useState({
    email: user?.email,
  });

  // Listen for changes in the user state and rerender
  useEffect(() => {
    if (user) {
      setFormData({ ...formData, email: user.email });
    }
  }, [user]);
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await fetch(`https://api-bank.bitinverse.com/api/v1/uploadkyc`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.msg || "Something went wrong!");
      }

      // setMessage("KYC submitted successfully");
      // setMessageID("successAlert");
      // setShowMessage(true);
      setIsLoading(false);      

      // Update user state globally
      updateUser(result);
    } catch (error) {
      setMessage(error.message || "An unexpected error occurred");
      setMessageID("declineAlert");
      setShowMessage(true);
      setIsLoading(false);
    }
  };

  // const handleAddProfile = (e) => {
  //   const file = e.target.files[0];
  //   setFileName(file.name);

  //   if (file?.size > 2 * 1024 * 1024) {
  //     setMessage("File size should not exceed 2MB");
  //     setMessageID("declineAlert");
  //     setShowMessage(true);
  //     return;
  //   }

  //   const reader = new FileReader();
  //   reader.readAsDataURL(file);

  //   reader.onloadend = () => {
  //     const image = new Image();
  //     image.src = reader.result;

  //     image.onload = () => {
  //       const canvas = document.createElement("canvas");
  //       const ctx = canvas.getContext("2d");

  //       const maxWidth = 600;
  //       const maxHeight = 600;
  //       let width = image.width;
  //       let height = image.height;

  //       if (width > height) {
  //         if (width > maxWidth) {
  //           height *= maxWidth / width;
  //           width = maxWidth;
  //         }
  //       } else {
  //         if (height > maxHeight) {
  //           width *= maxHeight / height;
  //           height = maxHeight;
  //         }
  //       }

  //       canvas.width = width;
  //       canvas.height = height;
  //       ctx.drawImage(image, 0, 0, width, height);

  //       const compressedBase64 = canvas.toDataURL("image/jpeg", 0.9);
  //       setProfileImg(compressedBase64);
  //       setFormData((prevFormData) => ({
  //         ...prevFormData,
  //         kyc: compressedBase64,
  //       }));

  //       setImgUpdated(true);
  //     };
  //   };
  // };
  
  const handleAddProfile = (e) => {
    const file = e.target.files[0];
    setFileName(file.name);
  
    if (file?.size > 2 * 1024 * 1024) { // Check if file size exceeds 2MB
      setMessage("File size should not exceed 2MB");
      setMessageID("declineAlert");
      setShowMessage(true);
      return;
    }
  
    const reader = new FileReader();
    reader.readAsDataURL(file); // Read the file as a Data URL
  
    reader.onloadend = () => {
      const image = new Image();
      image.src = reader.result;
  
      image.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
  
        const maxWidth = 600;
        const maxHeight = 600;
        let width = image.width;
        let height = image.height;
  
        // Resize the image while maintaining aspect ratio
        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }
  
        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(image, 0, 0, width, height); // Draw the resized image on the canvas
  
        // **Splitting the image into Top and Bottom halves**
  
        const halfHeight = height / 2; // Calculate half of the image height
  
        // Create two separate canvases for the top and bottom halves
        const topCanvas = document.createElement("canvas");
        const bottomCanvas = document.createElement("canvas");
        const topCtx = topCanvas.getContext("2d");
        const bottomCtx = bottomCanvas.getContext("2d");
  
        // Set the dimensions for the top and bottom canvases
        topCanvas.width = width;
        topCanvas.height = halfHeight;
        bottomCanvas.width = width;
        bottomCanvas.height = halfHeight;
  
        // Draw the top half of the image
        topCtx.drawImage(
          canvas,
          0,          // Source X
          0,          // Source Y
          width,      // Source Width
          halfHeight, // Source Height
          0,          // Destination X
          0,          // Destination Y
          width,      // Destination Width
          halfHeight  // Destination Height
        );
  
        // Draw the bottom half of the image
        bottomCtx.drawImage(
          canvas,
          0,             // Source X
          halfHeight,    // Source Y
          width,         // Source Width
          halfHeight,    // Source Height
          0,             // Destination X
          0,             // Destination Y
          width,         // Destination Width
          halfHeight     // Destination Height
        );
  
        // Convert both halves to Base64 strings
        const topBase64 = topCanvas.toDataURL("image/jpeg", 0.9);
        const bottomBase64 = bottomCanvas.toDataURL("image/jpeg", 0.9);
  
        // Store both parts in the form data
        setFormData((prevFormData) => ({
          ...prevFormData,
          kycTop: topBase64,      // Store the top half
          kycBottom: bottomBase64 // Store the bottom half
        }));
  
        // Optionally, set the profile image to the top half or any other logic
        setProfileImg(topBase64); // Example: setting the top half as the profile image
  
        // Indicate that the image has been updated
        setImgUpdated(true);
      };
    };
  };
  
  useEffect(() => {
    if (showMessage) {
      const timer = setTimeout(() => {
        setShowMessage(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [showMessage]);

  return (
    <>
      {showMessage && <MessageAlert data={{ message, messageID }} />}

      <div className="p-4 sm:ml-64 content-section">
        <div className="p-4 produce-form-wrapper">
          <div className="produce-form">
            {user?.kycStatus === "approved" ? (
              <div className="approveWrapper">
                <img src={successPng} alt="approved" style={{ width: "15%" }} />
                <p>KYC Verification Successful! You now have full access to all features.</p>
              </div>
            ) : user?.kycStatus === "pending" ? (
              <div className="approveWrapper">
                <img src={successPng} alt="pending" style={{ width: "15%" }} />
                <p>You have successfully uploaded your KYC Document. Please be patient while our team reviews it.</p>
              </div>
            ) : (
              <React.Fragment>
                <h3 className="mb-4 dashboard-label">KYC Document</h3>
                <p style={{ color: "gray" }}>
                  To serve you better, please provide original identifying documents. This will secure your account in case of recovery and grant you access to all features.
                </p>
                <p style={{ color: "gray" }}>
                  Acceptable proof includes a photo of your government-approved ID card, driver's license, or passport. We will notify you via email once this process is complete.
                </p>
                <span
                  style={{
                    backgroundColor: "rgb(77 135 77 / 92%)",
                    color: "rgb(2 255 2)",
                    padding: "10px",
                    borderRadius: "10px",
                  }}
                >
                  All data is safely stored and encrypted
                </span>
                <br />
                <br />
                <form onSubmit={handleSubmit}>
                  <div className="cheque-input">
                    <p style={{ color: "gray" }}>Drop file here or upload</p>
                    {fileName}
                    <input
                      type="file"
                      id="upload-cheque"
                      onChange={handleAddProfile}
                      accept=".jpg, .jpeg, .png"
                      required
                      hidden
                    />
                    <label htmlFor="upload-cheque" className="upload-cheque-btn">
                      Upload document
                    </label>
                  </div>

                  <div className="mt-8">
                    <button
                      type="submit"
                      className="upload-cheque-btn"
                      id="deposit-cheque-btn"
                      disabled={isLoading}
                    >
                      {isLoading ? <Loader /> : "Submit Upload"}
                    </button>
                  </div>
                </form>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default KYCSection;
