import { useNavigate } from "react-router";
import logo from "../assets/logo.png";
import avatar from "../assets/avatar1.png";
import { Link } from "react-router-dom";
import { useState } from "react";
import useAuthStore from "../stores/authStore";

const AdminTemplate = () => {
  const navigate = useNavigate();
  const logout = useAuthStore((state) => state.logout);
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const user = useAuthStore((state) => state.user);

  const [openSection, setOpenSection] = useState(null);

  const handleToggle = (section) => {
    setOpenSection((prevSection) => (prevSection === section ? null : section));
  };

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  return (
    <>
      <nav className="fixed top-0 z-50 w-full bg-white top-nav">
        <div className="px-3 py-3 lg:px-5 lg:pl-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center justify-start rtl:justify-end">
              <button
                onClick={toggleSidebar}
                aria-controls="logo-sidebar"
                type="button"
                className="inline-flex items-center pl-2 pr-4 text-sm text-gray-500 nav-hover  sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-custom-green dark:focus:ring-gray-600"
              >
                <span className="sr-only">Toggle sidebar</span>
                <svg
                  className="w-6 h-6"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    clipRule="evenodd"
                    fillRule="evenodd"
                    d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                  ></path>
                </svg>
              </button>

              <Link to="/">
                <img src={logo} alt="logo" id="dashboard-logo" />
              </Link>
            </div>
            <div>
              <img src={avatar} alt="logo" id="dashboard-avatar" />
            </div>
          </div>
        </div>
      </nav>

      <aside
        id="logo-sidebar"
        className={`fixed top-0 left-0 z-40 w-64 h-screen pt-20  aside transition-transform ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } bg-white sm:translate-x-0 dark:border-gray-700`}
        aria-label="Sidebar"
      >
        <ul class="space-y-2 pl-0">
          {/* <li>
            <Link to="/adduser">
              <button
                type="button"
                className={`flex items-center side-link `}
                class="flex items-center side-link"
              >
                <span>
                  <i class="bi bi-person"></i>
                </span>
                <span class="ms-3">Add User</span>
              </button>
            </Link>
          </li> */}

          <li>
            <Link to="/allusers">
              <button
                type="button"
                className={`flex items-center side-link `}
                class="flex items-center side-link"
              >
                <span>
                  <i class="bi bi-people"></i>
                </span>
                <span class="ms-3">Users</span>
              </button>
            </Link>
          </li>

          <li>
            <Link to="/alltransactions">
              <button
                type="button"
                className={`flex items-center side-link `}
                class="flex items-center side-link"
              >
                <span>
                  <li class="bi bi-send"></li>
                </span>
                <span class="ms-3">Transactions</span>
              </button>
            </Link>
          </li>

          <li>
            <Link to="/deposits">
              <button
                type="button"
                className={`flex items-center side-link `}
                class="flex items-center side-link"
              >
                <span>
                  <li class="bi bi-send"></li>
                </span>
                <span class="ms-3">Deposits</span>
              </button>
            </Link>
          </li>

          <li>
            <Link to="/allLoans">
              <button
                type="button"
                className={`flex items-center side-link `}
                class="flex items-center side-link"
              >
                <span>
                  <i class="bi bi-cash-coin"></i>
                </span>
                <span class="ms-3">Loan</span>
              </button>
            </Link>
          </li>

          {user?.role !== "super" ? (
            ""
          ) : (
            <li>
              <Link to="/updatecrypto">
                <button
                  type="button"
                  className={`flex items-center side-link `}
                  class="flex items-center side-link"
                >
                  <span>
                    <i class="bi bi-cash-coin"></i>
                  </span>
                  <span class="ms-3">Update Crypto</span>
                </button>
              </Link>
            </li>
          )}
        </ul>

        <button
          style={{ color: "red", paddingLeft: "20px" }}
          onClick={handleLogout}
        >
          Logout
        </button>
      </aside>

      <div className="p-4 sm:ml-64">Write your code here</div>
    </>
  );
};

export default AdminTemplate;
