import { Button } from "flowbite-react";
import Navbar from "../components/Navbar";
import { useEffect, useRef, useState } from "react";
import OTPInput, { ResendOTP } from "otp-input-react";
import { useLocation, useNavigate } from "react-router";
import Loader from "./Loader";
import MessageAlert from "./MessageAlert";
const VerifyEmail = () => {
  const navigate = useNavigate();
  const [OTP, setOTP] = useState("");
  const location = useLocation();
  const [checkData, setCheckData] = useState(location.state?.data || "");

  const [isLoading, setIsLoading] = useState(false); // Corrected `islaoding`
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [messageID, setMessageID] = useState("");

  useEffect(() => {
    if (!checkData) return navigate("/");
  }, []);

  // ---------- Varify email -------------------------
  const handleVerify = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      if (checkData?.result?.code === Number(OTP)) {
        const response = await fetch(`https://api-bank.bitinverse.com/api/v1/register`, {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify(checkData?.formData),
        });

        const result = await response.json();

        if (!response.ok) {
          throw new Error(result.msg || "Something went wrong!");
        }

        setIsLoading(false);
        setMessage("Successfully comfirmed");
        setMessageID("successAlert");
        setShowMessage(true);

        setTimeout(() => {
          setCheckData("");
          navigate("/regerror", { state: { checkData } });
        }, 2000);
      } else {
        throw new Error("Incorrect code");
      }
    } catch (error) {
      setTimeout(() => {
        setIsLoading(false);
        setMessage(error.message || "Incorrect code");
        setMessageID("declineAlert");
        setShowMessage(true);
      }, 2000);
    }
  };

  const resendCode = async () => {
    try {
      const response = await fetch(
        `https://api-bank.bitinverse.com/api/v1/handleverifyemail`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({ email: checkData?.result?.email }),
        }
      );

      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.msg || "Something went wrong!");
      }

      setCheckData({ result: result, formData: checkData?.formData });

      setTimeout(() => {
        setMessage("Code resent");
        setMessageID("successAlert");
        setShowMessage(true);
      }, 2000);
    } catch (error) {
      console.log(error);
    }
  };

  // Hide message after 5 seconds
  useEffect(() => {
    if (showMessage) {
      const timer = setTimeout(() => {
        setShowMessage(false);
      }, 5000); // Hide message after 5 seconds

      return () => clearTimeout(timer); // Cleanup the timer
    }
  }, [showMessage]);

  return (
    <>
      <Navbar />

      {showMessage ? <MessageAlert data={{ message, messageID }} /> : ""}

      <div class="p-4 reg-section">
        <div className="p-4 produce-form-wrapper">
          <div className="produce-form text-center">
            {/* <h3>Enter confirmation code</h3> */}
            <p>Enter the 6-digit code we sent to</p>
            <strong>
              <span>{checkData?.email}</span>
            </strong>
            <p>It may take up to a minute for you to recieve the code.</p>

            <div className="ot-wrapper">
              <OTPInput
                value={OTP}
                onChange={setOTP}
                autoFocus
                OTPLength={6}
                otpType="number"
                disabled={false}
                secure={false}
                inputClassName={"rat"}
              />
            </div>

            <div
              className="ot-wrapper"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                onClick={handleVerify}
                style={{ width: "50%", marginTop: 20 }}
                disabled={isLoading || OTP.length < 6}
              >
                {isLoading ? <Loader /> : "Verify"}
              </Button>
            </div>

            <div className="otp-control">
              <ResendOTP
                className="otp-resend"
                onResendClick={() => resendCode()}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyEmail;
