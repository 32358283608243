import { useEffect, useState } from "react";
import Loader from "../components/Loader";
import Navbar from "../components/Navbar";
import { useLocation, useNavigate } from "react-router";
import MessageAlert from "../components/MessageAlert";

const ChangePassword = () => {
  const [isLoading, setIsLoading] = useState();
  const location = useLocation();
  const navigate = useNavigate();
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [messageID, setMessageID] = useState("");

  const [data, setData] = useState(location.state?.result || "");
  const [formData, setFormData] = useState({
    confirmationCode: "",
    newPassword: "",
    confirmPassword: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    if (!data) return navigate("/");
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      if (Number(data?.code) !== Number(formData.confirmationCode)) {
        throw new Error("Invalid confirmation code");
      } else if (formData.newPassword !== formData.confirmPassword) {
        throw new Error("Password does not match");
      }

      const response = await fetch(
        `https://api-bank.bitinverse.com/api/v1/resetpassword`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: data?.email,
            password: formData?.newPassword,
          }),
        }
      );

      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.message || "Something went wrong");
      }

      setMessage(result);
      setMessageID("successAlert");
      setShowMessage(true);

      setTimeout(() => {
        navigate("/login");
      }, 4000);
    } catch (error) {
      console.log(error.message);
      setMessage(error.message || "Something went wrong");
      setMessageID("declineAlert");
      setShowMessage(true);
    } finally {
      setIsLoading(false);
    }
  };

  // Hide message after 5 seconds
  useEffect(() => {
    if (showMessage) {
      const timer = setTimeout(() => {
        setShowMessage(false);
      }, 5000); // Hide message after 5 seconds

      return () => clearTimeout(timer); // Cleanup the timer
    }
  }, [showMessage]);

  const back = () => {
    navigate("/login");
  };

  return (
    <>
      <Navbar />

      {showMessage ? <MessageAlert data={{ message, messageID }} /> : ""}

      <div class="p-4 reg-section">
        <div className="p-4 produce-form-wrapper">
          <div className="produce-form">
            <form class="" onSubmit={handleSubmit}>
              <h2>Reset password</h2>

              <div class="mb-4 mt-8">
                <label
                  for="type"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Validation code
                </label>
                <input
                  type="number"
                  id="type"
                  class="shadow-sm produce-input block w-full p-2.5 "
                  name="confirmationCode"
                  value={formData.confirmationCode}
                  onChange={handleChange}
                  required
                />
              </div>

              <div class="mb-4 mt-8">
                <label
                  for="type"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  New password
                </label>
                <input
                  type="password"
                  id="type"
                  class="shadow-sm produce-input block w-full p-2.5 "
                  name="newPassword"
                  value={formData.newPassword}
                  onChange={handleChange}
                  required
                />
              </div>

              <div class="mb-4 mt-8">
                <label
                  for="type"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Confirm password
                </label>
                <input
                  type="password"
                  id="type"
                  class="shadow-sm produce-input block w-full p-2.5 "
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  required
                />
              </div>

              <button
                disabled={isLoading}
                style={{ backgroundColor: "#216206", marginBottom: "30px" }}
                type="submit"
                class="text-white w-full  focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              >
                {isLoading ? <Loader /> : "Change password"}
              </button>

              <span onClick={back} style={{ color: "orangered", cursor: "pointer" }}>
                Back to login
              </span>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
